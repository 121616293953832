html, body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background-color: rgb(245,245,245);
}

.container{
  margin: 0 auto 0 auto;
  max-width: 1200px;
}

.nav-container{
  margin: auto;
}

a{
  text-decoration: none;
}

.MuiCardMedia-root{
  background-size: auto !important;
}